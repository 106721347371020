import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

import * as styles from './subscription.module.scss'
import Form from '../Form'

const Subscription = ({ title, description }) => {
    const { allWp } = useStaticQuery(
        graphql`
            query {
                allWp {
                    nodes {
                        themeGeneralSettings {
                            subscription {
                                subscriptionDescription
                                subscriptionTitle
                            }
                        }
                    }
                }
            }
        `
    )
    const generalData = allWp.nodes[0].themeGeneralSettings
    const wpData = generalData.subscription

    return wpData && wpData.subscriptionTitle ? (
        <section className={styles.wrapper}>
            <div className='global-container'>
                <div className={styles.column}>
                    <h2
                        data-sal='slide-up'
                        data-sal-delay='150'
                        data-sal-duration='1500'
                        data-sal-easing='ease-out-back'
                        className={styles.title}
                    >
                        {wpData.subscriptionTitle}
                    </h2>
                    <p
                        data-sal='slide-up'
                        data-sal-delay='350'
                        data-sal-duration='1500'
                        data-sal-easing='ease-out-back'
                        className={styles.description}
                    >
                        {wpData.subscriptionDescription}
                    </p>
                </div>
                <div className={styles.column}>
                    <Form formType='email' formId='email' formName='footer-contact' />
                </div>
            </div>
        </section>
    ) : null
}

Subscription.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

Subscription.defaultProps = {
    title: ``,
    description: ``,
}

export default Subscription
