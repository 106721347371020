import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Menu } from 'antd'
import * as styles from './menu.module.scss'

const GlobalMenu = props => {
    const menuQuery = useStaticQuery(graphql`
        query menu {
            wpMenu(name: { eq: "Header menu" }) {
                slug
                menuItems {
                    nodes {
                        label
                        path
                        id
                        parentId
                        parentDatabaseId
                        childItems {
                            nodes {
                                label
                                path
                                id
                                parentId
                                parentDatabaseId
                                childItems {
                                    nodes {
                                        label
                                        path
                                        id
                                        parentId
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const { SubMenu, Item } = Menu
    const menuItems = menuQuery.wpMenu.menuItems.nodes

    return (
        <>
            <Menu
                // triggerSubMenuAction='click'
                className={styles.menuWrapper}
                disabledOverflow={true}
                subMenuOpenDelay={0}
                mode={props.mode ? props.mode : 'horizontal'}
            >
                {menuItems.map(item => {
                    return !item.parentId && item.childItems.nodes.length > 0 ? (
                        <SubMenu
                            key={item.id}
                            popupOffset={[0, 0]}
                            title={
                                <Link to={item.path.replace('/en/', '/')} activeClassName='ant-menu-item-active'>
                                    {item.label}
                                </Link>
                            }
                        >
                            {item.childItems.nodes.map(subItem => {
                                return subItem.childItems.nodes.length > 0 ? (
                                    <SubMenu
                                        key={subItem.id}
                                        popupOffset={[0, 0]}
                                        title={
                                            <Link
                                                to={subItem.path.replace('/en/', '/')}
                                                activeClassName='ant-menu-item-active'
                                            >
                                                {subItem.label}
                                            </Link>
                                        }
                                    >
                                        {subItem.childItems.nodes.map(subSubItem => (
                                            <Item key={subSubItem.id}>
                                                <Link
                                                    to={subSubItem.path.replace('/en/', '/')}
                                                    activeClassName={'ant-menu-item-active'}
                                                >
                                                    {subSubItem.label}
                                                </Link>
                                            </Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Item key={subItem.id}>
                                        <Link
                                            to={subItem.path.replace('/en/', '/')}
                                            activeClassName={'ant-menu-item-active'}
                                        >
                                            {subItem.label}
                                        </Link>
                                    </Item>
                                )
                            })}
                        </SubMenu>
                    ) : !item.parentDatabaseId ? (
                        <Item key={item.id}>
                            <Link to={item.path.replace('/en/', '/')} activeClassName={'ant-menu-item-active'}>
                                {item.label}
                            </Link>
                        </Item>
                    ) : null
                })}
            </Menu>
        </>
    )
}

export default GlobalMenu
