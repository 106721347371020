import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

const CommonContacts = ({ withIcon }) => {
    const { allWp } = useStaticQuery(
        graphql`
            query {
                allWp {
                    nodes {
                        themeGeneralSettings {
                            generalContacts {
                                contactsEmail
                                contactsPhone
                            }
                        }
                    }
                }
            }
        `
    )
    const generalData = allWp.nodes[0].themeGeneralSettings

    return (
        <div className='global-common-contacts'>
            <a href={`tel:${generalData.generalContacts.contactsPhone.replace(/ /g, '')}`}>
                {withIcon && (
                    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M11.209 2.8125C12.1626 3.06896 13.032 3.57147 13.7302 4.26971C14.4285 4.96794 14.931 5.83739 15.1874 6.79096'
                            stroke='#171721'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M10.627 4.98639C11.1991 5.14026 11.7208 5.44177 12.1397 5.86071C12.5587 6.27966 12.8602 6.80133 13.014 7.37347'
                            stroke='#171721'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M6.50224 8.77605C7.08572 9.96893 8.05266 10.9315 9.24818 11.5095C9.33565 11.551 9.4324 11.5689 9.52892 11.5616C9.62544 11.5543 9.71839 11.522 9.79863 11.4679L11.559 10.294C11.6368 10.2421 11.7264 10.2105 11.8196 10.2019C11.9127 10.1933 12.0066 10.2082 12.0926 10.245L15.3858 11.6564C15.4977 11.7039 15.5911 11.7865 15.652 11.8917C15.7128 11.997 15.7378 12.1191 15.7232 12.2398C15.6191 13.0543 15.2217 13.8029 14.6054 14.3455C13.9891 14.8881 13.1961 15.1874 12.375 15.1875C9.83887 15.1875 7.40661 14.18 5.61329 12.3867C3.81997 10.5934 2.8125 8.16111 2.8125 5.62498C2.81254 4.80385 3.11189 4.01089 3.65448 3.39458C4.19707 2.77826 4.94571 2.38086 5.76021 2.27677C5.88088 2.26216 6.00302 2.28717 6.10824 2.34802C6.21346 2.40887 6.29605 2.50227 6.34357 2.61414L7.75619 5.91024C7.79272 5.9955 7.80761 6.08847 7.79952 6.18087C7.79144 6.27327 7.76062 6.36224 7.70983 6.43985L6.54008 8.22718C6.48684 8.30758 6.45537 8.40042 6.44874 8.49662C6.4421 8.59283 6.46054 8.6891 6.50224 8.77605V8.77605Z'
                            stroke='#171721'
                            strokeLinejoin='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                )}
                {generalData.generalContacts.contactsPhone}
            </a>
            <a href={`mailto:${generalData.generalContacts.contactsEmail.replace(/ /g, '')}`}>
                {withIcon && (
                    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M2.25 3.9375H15.75V13.5C15.75 13.6492 15.6907 13.7923 15.5852 13.8977C15.4798 14.0032 15.3367 14.0625 15.1875 14.0625H2.8125C2.66332 14.0625 2.52024 14.0032 2.41475 13.8977C2.30926 13.7923 2.25 13.6492 2.25 13.5V3.9375Z'
                            stroke='black'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M15.75 3.9375L9 10.125L2.25 3.9375'
                            stroke='black'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                )}
                {generalData.generalContacts.contactsEmail}
            </a>
        </div>
    )
}

CommonContacts.defaultProps = {
    withIcon: false,
}

CommonContacts.propTypes = {
    withIcon: PropTypes.bool,
}

export default CommonContacts
