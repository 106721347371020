import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './social.module.scss'

const SocialList = props => {
    const { allWp } = useStaticQuery(
        graphql`
            query {
                allWp {
                    nodes {
                        themeGeneralSettings {
                            generalSocialList {
                                socialList {
                                    link
                                    title
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    const { theme } = props
    const generalData = allWp.nodes[0].themeGeneralSettings

    return (
        <nav className={styles.wrapper}>
            {generalData.generalSocialList.socialList.map(item => {
                return (
                    <a
                        key={item.title}
                        href={item.link.replace(/ /g, '')}
                        aria-label={item.title}
                        className={theme !== 'white' && item.title === 'Animated' ? styles.animated : ''}
                    >
                        {item.title === 'Whatsapp' && (
                            <svg
                                role='img'
                                width='42'
                                height='42'
                                fill='none'
                                viewBox='0 0 42 42'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <circle cx='21' cy='21' r='21' fill='#46B41F' />
                                <path
                                    d='M11.969 26.36a10.496 10.496 0 1 1 3.672 3.671h0l-3.627 1.036a.874.874 0 0 1-1.081-1.081l1.036-3.627h0Z'
                                    stroke='#fff'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M23.637 27.125a8.75 8.75 0 0 1-8.762-8.762 3.06 3.06 0 0 1 3.063-3.05v0a.754.754 0 0 1 .654.38l1.279 2.237a.874.874 0 0 1-.01.884l-1.027 1.712a5.27 5.27 0 0 0 2.64 2.64v0l1.712-1.027a.875.875 0 0 1 .884-.01l2.238 1.279a.756.756 0 0 1 .38.654v0a3.071 3.071 0 0 1-3.05 3.063v0Z'
                                    stroke='#fff'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        )}
                        {item.title === 'Telegram' && (
                            <svg
                                role='img'
                                width='64'
                                height='64'
                                fill='none'
                                viewBox='0 0 64 64'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <defs>
                                    <clipPath id='A'>
                                        <path d='M15.903 131.998c0-65.17 52.83-118 118-118s118 52.83 118 118-52.83 118-118 118-118-52.83-118-118' />
                                    </clipPath>
                                    <linearGradient
                                        x1='133.903'
                                        y1='13.999'
                                        x2='133.903'
                                        y2='249.999'
                                        gradientUnits='userSpaceOnUse'
                                        spreadMethod='pad'
                                        id='B'
                                    >
                                        <stop offset='0' stopColor='#1d93d2' />
                                        <stop offset='1' stopColor='#38b0e3' />
                                    </linearGradient>
                                    <clipPath id='C'>
                                        <path d='M0 265.9h266.987V0H0z' />
                                    </clipPath>
                                    <clipPath id='D'>
                                        <path d='M0 265.9h266.987V0H0z' />
                                    </clipPath>
                                    <clipPath id='E'>
                                        <path d='M0 265.9h266.987V0H0z' />
                                    </clipPath>
                                </defs>
                                <g transform='matrix(.271187 0 0 -.271187 -4.312678 67.796339)'>
                                    <path
                                        d='M15.903 131.998c0-65.17 52.83-118 118-118s118 52.83 118 118-52.83 118-118 118-118-52.83-118-118'
                                        fill='url(#B)'
                                        clipPath='url(#A)'
                                    />
                                    <g clipPath='url(#C)'>
                                        <path
                                            d='M95.778 123.374l14-38.75S111.528 81 113.403 81s29.75 29 29.75 29l31 59.875-77.875-36.5z'
                                            fill='#c8daea'
                                        />
                                    </g>
                                    <g clipPath='url(#D)'>
                                        <path
                                            d='M114.34 113.436l-2.688-28.562s-1.125-8.75 7.625 0 17.125 15.5 17.125 15.5'
                                            fill='#a9c6d8'
                                        />
                                    </g>
                                    <g clipPath='url(#E)'>
                                        <path
                                            d='M96.03 121.99l-28.795 9.383s-3.437 1.395-2.333 4.562c.228.653.687 1.208 2.062 2.167 6.382 4.447 118.104 44.604 118.104 44.604s3.155 1.062 5.02.356c.852-.323 1.396-.688 1.854-2.02.167-.485.263-1.516.25-2.542-.01-.74-.1-1.425-.166-2.5-.68-10.98-21.04-92.918-21.04-92.918s-1.218-4.795-5.583-4.958c-1.592-.06-3.524.263-5.834 2.25-8.565 7.368-38.172 27.265-44.713 31.64-.37.246-.474.567-.537.88-.092.46.4 1.034.4 1.034s51.552 45.825 52.924 50.633c.106.373-.293.557-.834.396-3.424-1.26-62.78-38.74-69.33-42.88-.383-.242-1.457-.086-1.457-.086'
                                            fill='#fff'
                                        />
                                    </g>
                                </g>
                            </svg>
                        )}
                        {item.title === 'Animated' && (
                            <>
                                <svg
                                    role='img'
                                    width='42'
                                    height='42'
                                    fill='none'
                                    viewBox='0 0 42 42'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <circle cx='21' cy='21' r='21' fill={theme === 'white' ? '#C8DAEA' : '#000'} />
                                    <path
                                        d='M12.063 15.156h11a2.75 2.75 0 0 1 2.75 2.75v8.25a.687.687 0 0 1-.688.688h-11a2.75 2.75 0 0 1-2.75-2.75v-8.25a.687.687 0 0 1 .688-.688v0Z'
                                        stroke={theme === 'white' ? '#050522' : '#fff'}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='m25.813 19.625 4.812-2.75v8.25l-4.813-2.75'
                                        stroke={theme === 'white' ? '#050522' : '#fff'}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </>
                        )}
                    </a>
                )
            })}
        </nav>
    )
}

SocialList.defaultProps = {
    theme: `white`,
}

SocialList.propTypes = {
    theme: PropTypes.string.isRequired,
}

export default SocialList
