import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import EmailForm from './EmailForm'
import EstimateForm from './EstimateForm'
import ContactForm from './ContactForm'

import * as styles from './form.module.scss'

const Form = ({ formType, formId, layout, calculatorData }) => {
    const [bot, setBot] = useState('')
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [text, setText] = useState('')
    const [message, setMessage] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
    const isEstimateForm = formType === 'calculator' || formType === 'sidebar' || formType === 'estimate'

    useEffect(() => {
        setMessage('')
    }, [calculatorData])

    const getQueryStringParams = () => {
        let urlParams,
            match,
            pl = /\+/g,
            search = /([^&=]+)=?([^&]*)/g,
            decode = function (s) {
                return decodeURIComponent(s.replace(pl, ' '))
            },
            query = window.location.search.substring(1)

        urlParams = {}
        while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])

        return urlParams
    }

    const setSubmittedSuccess = () => {
        setSubmitted(false)
        setIsSubmitSuccess(true)
        setMessage('Мы свяжемся с вами в ближайшие несколько часов. 😊')
    }

    const handleSubmit = (e, type) => {
        e && e.preventDefault()

        const isBrowser = typeof window !== 'undefined'
        const pageUri = isBrowser ? window.location.href : null
        const pageTitle = isBrowser ? document.title : null
        const urlParams = getQueryStringParams()
        const formTitle =
            type && type === 'email'
                ? 'Подписка на рассылку'
                : type && type === 'estimate'
                ? 'Заявка на расчет стоимости'
                : type && type === 'contact'
                ? 'Форма обратной связи (Контакты)'
                : ''

        const postUrl = 'https://content.ok-p.com/wp-json/myplugin/v1/integration'
        const putUrl = 'https://content.ok-p.com/wp-json/myplugin/v1/formsave'
        // const testUrl = `https://b24-okh02l.bitrix24.ua/rest/1/lnpna79jgmpddnng/crm.lead.add.json?FIELDS[TITLE]='Заявка из Калькулятора'&FIELDS[NAME]=${name}&FIELDS[EMAIL][0][VALUE]=${email}&FIELDS[UF_CRM_1647544232]='residency'`
        const formData =
            formType === 'calculator'
                ? {
                      title: 'Заявка из Калькулятора',
                      email: email,
                      name: name,
                      ...calculatorData,
                      ...urlParams,
                  }
                : formType === 'sidebar'
                ? {
                      title: 'Получить информацию о продукте',
                      pageTitle: pageTitle,
                      email: email,
                      name: name,
                      ...urlParams,
                  }
                : {
                      title: formTitle,
                      email: email,
                      name: name,
                      phone: phone,
                      message: text,
                      ...urlParams,
                  }

        if (bot !== '') alert("Yoy've entered the bot field with data")

        setLoading(true)

        fetch(formType === 'calculator' ? postUrl : putUrl, {
            method: 'put',
            cache: 'no-cache',
            body: JSON.stringify(formData),
        })
            .then(res => {
                console.log(res)

                setEmail('')
                setName('')
                setText('')
                setPhone('')
                setSubmitted(true)
                setLoading(false)

                res && res.status === 'error'
                    ? setMessage('Упс! Похоже, что-то пошло не так. 🤔 Пожалуйста, попробуйте позже еще раз ...')
                    : setSubmittedSuccess()
            })
            .catch(error => {
                console.log(error)

                setEmail('')
                setName('')
                setText('')
                setPhone('')
                setSubmitted(true)
                setLoading(false)
                setSubmittedSuccess()
                // setMessage('Упс! Похоже, что-то пошло не так. 🤔 Пожалуйста, попробуйте позже еще раз ...')
            })
    }

    return (
        <div className={styles.wrapper}>
            {formType === 'email' && (
                <EmailForm
                    formId={formId}
                    bot={bot}
                    setBot={setBot}
                    loading={loading}
                    message={message}
                    handleSubmit={handleSubmit}
                    isSubmitSuccess={isSubmitSuccess}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {isEstimateForm && (
                <EstimateForm
                    formType={formType}
                    formId={formId}
                    layout={layout}
                    bot={bot}
                    setBot={setBot}
                    loading={loading}
                    message={message}
                    handleSubmit={handleSubmit}
                    isSubmitSuccess={isSubmitSuccess}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    email={email}
                    setEmail={setEmail}
                    name={name}
                    setName={setName}
                    phone={phone}
                    setPhone={setPhone}
                />
            )}
            {formType === 'contact' && (
                <ContactForm
                    formId={formId}
                    layout={layout}
                    bot={bot}
                    setBot={setBot}
                    loading={loading}
                    message={message}
                    handleSubmit={handleSubmit}
                    isSubmitSuccess={isSubmitSuccess}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    email={email}
                    setEmail={setEmail}
                    name={name}
                    setName={setName}
                    text={text}
                    setText={setText}
                />
            )}
        </div>
    )
}

Form.propTypes = {
    formType: PropTypes.string,
    formId: PropTypes.string,
    formName: PropTypes.string,
    layout: PropTypes.string,
}

Form.defaultProps = {
    formType: `email`,
    formId: ``,
    formName: ``,
    layout: `row`,
}

export default Form
