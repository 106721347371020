import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ lang, title, meta }) => {
    const { wp, wpUser } = useStaticQuery(
        graphql`
            query {
                wp {
                    generalSettings {
                        title
                        description
                    }
                }

                # if there's more than one user this would need to be filtered to the main user
                wpUser {
                    twitter: name
                }
            }
        `
    )
    const {
        metaDesc,
        focuskw,
        opengraphTitle,
        opengraphType,
        opengraphUrl,
        opengraphSiteName,
        opengraphDescription,
        opengraphImage,
        twitterTitle,
        twitterDescription,
        twitterImage,
        fullHead,
    } = meta

    const language = lang ? lang : 'ru'
    const metaDescription = metaDesc || wp.generalSettings?.description
    const defaultTitle = wp.generalSettings?.title
    const ldJsonContent = fullHead
        ? fullHead
              .match(/class="yoast-schema-graph">([^;]+)/)[1]
              .replace('</script>', '')
              .replace('<!-- / Yoast SEO plugin. -->', '')
        : ''

    return (
        <Helmet
            htmlAttributes={{ language }}
            title={title}
            // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: opengraphTitle,
                },
                {
                    property: `og:description`,
                    content: opengraphDescription,
                },
                {
                    property: `og:type`,
                    content: opengraphType,
                },
                // {
                //     property: `og:url`,
                //     content: opengraphUrl,
                // },
                {
                    property: `og:image`,
                    content: opengraphImage ? opengraphImage.sourceUrl : '',
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: twitterTitle,
                },
                {
                    name: `twitter:description`,
                    content: twitterDescription,
                },
                {
                    property: `twitter:image`,
                    content: twitterImage ? twitterImage.link : '',
                },
            ].concat(meta)}
            script={[{ type: 'application/ld+json', innerHTML: ldJsonContent }]}
        />
    )
}

export default Seo
