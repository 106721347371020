// extracted by mini-css-extract-plugin
export var wrapper = "form-module--wrapper--KfC7h";
export var form = "form-module--form--4i8TI";
export var formRowHidden = "form-module--formRowHidden--LA5Z+";
export var formRow = "form-module--formRow--Kptge";
export var formItem = "form-module--formItem--KyiMY";
export var formField = "form-module--formField--xmm4R";
export var formButton = "form-module--formButton--2JvmI";
export var formRowColumn = "form-module--formRowColumn---m1Re form-module--formRow--Kptge";
export var formSubItem = "form-module--formSubItem--IPVAR";
export var formRowFullWidth = "form-module--formRowFullWidth--PJwRh";
export var formMessage = "form-module--formMessage--seXFm";
export var formLabelContent = "form-module--formLabelContent--QnYLh";
export var formLabel = "form-module--formLabel--vLloS";
export var formLoader = "form-module--formLoader--K3E8X";
export var success = "form-module--success--hGacL";
export var formMessageAbsolute = "form-module--formMessageAbsolute--txtP7 form-module--formMessage--seXFm";