import React, { useRef } from 'react'

import DoneIcon from '../../assets/svg/done.inline.svg'
import * as styles from './form.module.scss'

const EstimateForm = ({
    formType,
    formId,
    layout,
    loading,
    message,
    formName,
    email,
    name,
    handleSubmit,
    submitted,
    isSubmitSuccess,
    setBot,
    setEmail,
    setName,
    phone,
    setPhone,
}) => {
    const emailInputEl = useRef(null)
    const nameInputEl = useRef(null)
    const phoneInputEl = useRef(null)
    const submitClass = formType === 'estimate' ? ' estimate-form' : ' sidebar-form'

    return (
        <form id={formId ? formId : 'contact'} onSubmit={e => handleSubmit(e, 'estimate')}>
            <input type='hidden' name='bot' onChange={e => setBot(e.target.value)} />
            <input type='hidden' name='form-name' value={formName ? formName : 'contact'} />
            <div className={layout === 'column' ? styles.formRowColumn : styles.formRow}>
                {isSubmitSuccess ? (
                    <div className={styles.success}>
                        <DoneIcon />
                        <h5>Спасибо!</h5>
                        <p className={styles.formMessage} dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                ) : (
                    <>
                        <div className={styles.formItem}>
                            <label>
                                <input
                                    ref={nameInputEl}
                                    type='text'
                                    name='name'
                                    id={formName + 'name'}
                                    className={styles.formField}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    disabled={loading}
                                    placeholder='Ваше Имя'
                                    required
                                />
                            </label>
                        </div>
                        <div className={styles.formItem}>
                            <label>
                                <input
                                    ref={emailInputEl}
                                    type='email'
                                    name='email'
                                    id={formName + 'email'}
                                    className={styles.formField}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    disabled={loading}
                                    placeholder='Ваш Email'
                                    required
                                />
                            </label>
                        </div>
                        {formType === 'estimate' && (
                            <div className={styles.formItem}>
                                <label>
                                    <input
                                        pattern='[0-9]{1,15}'
                                        ref={phoneInputEl}
                                        type='tel'
                                        name='phone'
                                        id={formName + 'phone'}
                                        className={styles.formField}
                                        onChange={e => setPhone(e.target.value)}
                                        value={phone}
                                        disabled={loading}
                                        placeholder='Ваш Телефон'
                                        required
                                    />
                                </label>
                            </div>
                        )}
                        <button type='submit' className={styles.formButton + submitClass} disabled={loading}>
                            <span style={{ display: 'inline-block', marginTop: 2 + 'px' }}>Оставить заявку</span>
                            {loading && <span className={styles.formLoader} />}
                        </button>
                        {submitted && (
                            <p className={styles.formMessage} dangerouslySetInnerHTML={{ __html: message }} />
                        )}
                    </>
                )}
            </div>
        </form>
    )
}

export default EstimateForm
