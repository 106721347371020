import React from 'react'
import PropTypes from 'prop-types'

import Footer from '../Footer'
import Header from '../Header'
import Subscription from '../Subscription'
import * as styles from './layout.module.scss'

const Layout = ({ bodyClass, children, pageUri }) => {
    return (
        <div className={'global-wrapper ' + bodyClass}>
            <Header pageUri={pageUri} />

            <main className={styles.wrapper}>{children}</main>

            <Subscription />
            <Footer />
        </div>
    )
}

Layout.defaultProps = {
    bodyClass: '',
}

Layout.propTypes = {
    bodyClass: PropTypes.string,
    children: PropTypes.node,
}

export default Layout
