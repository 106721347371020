import React from 'react'
import { Link } from 'gatsby'
import { Grid } from 'antd'

import SocialList from '../SocialList'
import CommonContacts from '../CommonContacts'
import * as styles from './footer.module.scss'

const Footer = () => {
    const { useBreakpoint } = Grid
    const { lg } = useBreakpoint()

    return (
        <footer className={styles.wrapper}>
            <div className='global-container'>
                {lg ? (
                    <>
                        <div
                            data-sal='slide-up'
                            data-sal-delay='150'
                            data-sal-duration='1500'
                            data-sal-easing='ease-out-back'
                        >
                            <p>
                                © GetResidency, {new Date().getFullYear()}.{' '}
                                <Link to='/privacy-policy'>Пользовательское соглашение</Link>
                            </p>
                        </div>
                        <div
                            data-sal='slide-up'
                            data-sal-delay='150'
                            data-sal-duration='1500'
                            data-sal-easing='ease-out-back'
                        >
                            <CommonContacts />
                        </div>
                        <div
                            data-sal='slide-up'
                            data-sal-delay='150'
                            data-sal-duration='1500'
                            data-sal-easing='ease-out-back'
                        >
                            <SocialList theme='white' />
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            data-sal='slide-up'
                            data-sal-delay='150'
                            data-sal-duration='1500'
                            data-sal-easing='ease-out-back'
                        >
                            <SocialList theme='white' />
                        </div>
                        <div
                            data-sal='slide-up'
                            data-sal-delay='150'
                            data-sal-duration='1500'
                            data-sal-easing='ease-out-back'
                        >
                            <CommonContacts />
                        </div>
                        <div
                            data-sal='slide-up'
                            data-sal-delay='150'
                            data-sal-duration='1500'
                            data-sal-easing='ease-out-back'
                        >
                            <p>
                                © GetResidency, {new Date().getFullYear()}.{' '}
                                <Link to='/privacy-policy'>Пользовательское соглашение</Link>
                            </p>
                        </div>
                    </>
                )}
            </div>
        </footer>
    )
}

export default Footer
